import * as React from 'react';
import qs from 'qs';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import matchPath from 'react-router/matchPath';
import loadData from '../../utils/loadData';
import * as propTypes from '../../propTypes';
import { hit } from '../../utils/metrika';
import { connectAppConfig } from 'src/shared/components/AppConfig';

const getRoute = (props) => {
  const { routes, location } = props;
  for (let i = 0; i < routes.length; i++) {
    const route = routes[i];
    const match = matchPath(location.pathname, route);

    if (match) {
      const query = qs.parse(location.search.substr(1), { arrayLimit: 0 });
      return { route, match, query, location };
    }
  }
  return { route: null, match: null, query: null, location: null };
};

class Router extends React.Component {
  static propTypes = {
    history:  propTypes.history,
    location: propTypes.location,
    match:    PropTypes.shape({
      isExact: PropTypes.bool,
      params:  PropTypes.objectOf(PropTypes.string),
      path:    PropTypes.string,
      url:     PropTypes.string,
    }),
    routes:   propTypes.routes,
    render:   PropTypes.func,
    hit:      PropTypes.bool,
  };

  static contextTypes = {
    store: PropTypes.shape(),
  };

  state = getRoute(this.props);

  componentWillReceiveProps(nextProps) {
    const nextState = getRoute(nextProps);
    const { route, match, query, location } = nextState;
    const { store } = this.context;

    if (location !== this.props.location) {
      this.index = this.index + 1 || 1;
      const currIndex = this.index;
      const options = { store, requester: nextProps.requester, params: match.params, query };
      route.component.load()
        .then(() => {
          const { preloadData } = route.component;
          if (this.index !== currIndex) return null;
          if (!preloadData) return null;
          return Promise.all(preloadData.map(action => loadData(options, action, () => this.index !== currIndex)));
        })
        .then(() => {
          if (this.index !== currIndex) return null;
          if (typeof route.onEnter !== 'function') return null;
          const context = {};
          const onEnter = route.onEnter(context, match.params, query, location.pathname + location.search);
          if (onEnter && onEnter.then) return onEnter.then(() => context);
          return context;
        })
        .then((routeContext) => {
          if (this.index !== currIndex) return;

          if (routeContext && routeContext.redirect) {
            this.props.history.replace(routeContext.redirect);
            return;
          }

          if (this.props.hit) hit(location.pathname + location.search);
          this.setState(nextState);
        });
    }
  }

  // renderComponent = ({context}) => {
  //
  // };

  render() {
    const { route, match, query, location } = this.state;
    const { history, render } = this.props;

    const Component = route.component;
    const props = {
      key:    `router-${this.props.routes.length}`,
      location,
      history,
      route,
      params: match.params,
      query,
    };
    if (typeof render === 'function') return render(Component, props);
    return <Component {...props} />;
  }
}

export default withRouter(connectAppConfig(Router));
