import loadable from '@loadable/component';
import { getBrands, getNewsList } from '../../../redux/api/redux-connect-actions';

const component = loadable(() => import('./BrandListContainer'));
component.preloadData = [
  getBrands,
  getNewsList(3),
];

export default component;
