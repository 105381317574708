/**
 *
 * @param {object} object
 * @param {function} callback
 */
export default (object, callback) => {
  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      callback(object[key], key);
    }
  }
};
