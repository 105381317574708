/* eslint-disable */

import browser from './browser';

export default function addEvent(elem, types, handler, useCapture) {
  // For IE
  if (elem.setInterval && elem !== window) elem = window;

  var events = data(elem, 'events') || data(elem, 'events', {}),
    handle = data(elem, 'handle') || data(elem, 'handle', function () {
      _eventHandle.apply(handle.elem, arguments);
    });
  // to prevent a memory leak
  handle.elem = elem;

  each(types.split(/\s+/), function (index, type) {
    if (!events[type]) {
      events[type] = [];
      if (elem.addEventListener) {
        elem.addEventListener(type, handle, useCapture);
      } else if (elem.attachEvent) {
        elem.attachEvent('on' + type, handle);
      }
    }
    events[type].push(handler);
  });

  elem = null;
}

function _eventHandle(event) {
  event = normEvent(event);

  var handlers = data(this, 'events');
  if (!handlers || typeof(event.type) !== 'string' || !handlers[event.type] || !handlers[event.type].length) {
    return;
  }

  var eventHandlers = (handlers[event.type] || []).slice();
  for (var i in eventHandlers) {
    if (event.type === 'mouseover' || event.type === 'mouseout') {
      var parent = event.relatedElement;
      while (parent && parent !== this) {
        try { parent = parent.parentNode; }
        catch (e) { parent = this; }
      }
      if (parent === this) {
        continue;
      }
    }
    var ret = eventHandlers[i].apply(this, arguments);
    if (ret === false || ret === -1) {
      cancelEvent(event);
    }
    if (ret === -1) {
      return false;
    }
  }
}

function each(object, callback) {
  if (!isObject(object) && typeof object.length !== 'undefined') {
    for (var i = 0, length = object.length; i < length; i++) {
      var value = object[i];
      if (callback.call(value, i, value) === false) break;
    }
  } else {
    for (var name in object) {
      if (!Object.prototype.hasOwnProperty.call(object, name)) continue;
      if (callback.call(object[name], name, object[name]) === false)
        break;
    }
  }

  return object;
}

function normEvent(event) {
  event = event || window.event;

  var originalEvent = event;
  event = clone(originalEvent);
  event.originalEvent = originalEvent;

  if (!event.target) {
    event.target = event.srcElement || document;
  }

  // check if target is a textnode (safari)
  if (event.target.nodeType === 3) {
    event.target = event.target.parentNode;
  }

  if (!event.relatedTarget && event.fromElement) {
    event.relatedTarget = event.fromElement === event.target;
  }

  if (event.pageX === null && event.clientX !== null) {
    var doc = document.documentElement, body = bodyNode;
    event.pageX = event.clientX + (doc && doc.scrollLeft || body && body.scrollLeft || 0) - (doc.clientLeft || 0);
    event.pageY = event.clientY + (doc && doc.scrollTop || body && body.scrollTop || 0) - (doc.clientTop || 0);
  }

  if (!event.which && ((event.charCode || event.charCode === 0) ? event.charCode : event.keyCode)) {
    event.which = event.charCode || event.keyCode;
  }

  if (!event.metaKey && event.ctrlKey) {
    event.metaKey = event.ctrlKey;
  } else if (!event.ctrlKey && event.metaKey && browser.mac) {
    event.ctrlKey = event.metaKey;
  }

  // click: 1 == left; 2 == middle; 3 == right
  if (!event.which && event.button) {
    event.which = (event.button & 1 ? 1 : ( event.button & 2 ? 3 : ( event.button & 4 ? 2 : 0 ) ));
  }

  return event;
}

function cancelEvent(event) {
  event = (event || window.event);
  if (!event) return false;
  while (event.originalEvent) {
    event = event.originalEvent;
  }
  if (event.preventDefault) event.preventDefault();
  if (event.stopPropagation) event.stopPropagation();
  event.cancelBubble = true;
  event.returnValue = false;
  return false;
}

function ge(el) {
  return (typeof el === 'string' || typeof el === 'number') ? document.getElementById(el) : el;
}

var vkExpand = 'VK' + +(new Date), vkUUID = 0, vkCache = {};

function data(elem, name, data) {
  if (!elem) return false;
  var id = elem[vkExpand], undefined;
  if (!id) {
    id = elem[vkExpand] = ++vkUUID;
  }

  if (data !== undefined) {
    if (!vkCache[id]) {
      vkCache[id] = {};
    }
    vkCache[id][name] = data;
  }

  return name ? vkCache[id] && vkCache[id][name] : id;
}

function isObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]' &&
    !(browser.msie8 && obj && obj.item !== 'undefined' &&
    obj.namedItem !== 'undefined');
}

function clone(obj, req) {
  var newObj = !isObject(obj) && typeof obj.length !== 'undefined' ? [] : {};
  for (var i in obj) {
    if (browser.webkit && (i === 'layerX' || i === 'layerY')) continue;
    if (req && typeof(obj[i]) === 'object' && i !== 'prototype') {
      newObj[i] = clone(obj[i]);
    } else {
      newObj[i] = obj[i];
    }

  }
  return newObj;
}
