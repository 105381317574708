import loadable from '@loadable/component';
import { getNews, getNewsList } from '../../../redux/api/redux-connect-actions';

const component = loadable(() => import('./NewsSinglePageContainer'));
component.preloadData = [
  getNewsList(3),
  getNews,
];

export default component;
