import stringify from 'qs/lib/stringify';
import _forEach from './collection/forEach';

const toBuffer = arr => arr.reduce((obj, value) => {
  obj[value] = true; // eslint-disable-line no-param-reassign
  return obj;
}, {});

const getTranslit = field => field && field.titleTranslit;

const locationParams = [
  'carType',
  'brand',
  'model',
  'generation',
  'partgroup',
  'partname',
  // 'barcode',
];

const searchParams = [
  'page',
  'used',
  'sort',
  'city',
  'minPrice',
  'maxPrice',
  'attr',
];

const searchPageParams = [
  // 'type',
  'q',
  // 'brand_id',
  // 'model_id',
  // 'generation_id',
  // 'partgroup_id',
  // 'partname_id',
  // 'page',
  // 'used',
  // 'sort',
];

const locationParamsDict = toBuffer(locationParams);
const searchParamsDict = toBuffer(searchParams);

/**
 * Фильтрует объект params, удаляя пустые занаечние и ключи, не входящие в availableParams
 * @param {object} params
 * @param {Array.<string>} availableParams
 */
const filterParams = (params, availableParams) => availableParams.reduce((buf, key) => {
  const param = params[key];
  if (param !== undefined && param !== '' && param !== null) buf[key] = param; // eslint-disable-line no-param-reassign
  return buf;
}, {});

const filterCargo = (brand) => { return brand.cargo ? 'trucks' : 'cars'; };

/**
 * Соритрует входные парамерты на 3 категории
 * @param {object} params
 * @return {{params: {}, query: {}, rest: {}}}
 */
function parseParams(params) {
  const buf = {
    params: {},
    query:  {},
    rest:   {},
  };

  _forEach(params, (value, key) => {
    if (value === null || value === undefined || value === '') return;
    if (locationParamsDict[key]) {
      buf.params[key] = value;
    } else if (searchParamsDict[key]) {
      buf.query[key] = value;
    } else {
      buf.rest[key] = value;
    }
  });

  return buf;
}

export function catalogPage(state, options) {
  const { params, query, rest } = parseParams(state);

  if (!query.page) query.page = 1;

  let url = '/';
  locationParams.forEach((param) => {
    url += `${params[param] || 'all'}/`;
  });

  if (rest.barcode) url += `${rest.barcode}/`;

  if (!options || options.addSearch === undefined || options.addSearch) {
    const strQuery = stringify(query, { arrayFormat: 'brackets' });
    if (strQuery.length) url += `?${strQuery}`;
  }
  if (options && options.hash !== null && options.hash !== undefined) url += `#${options.hash}`;

  return url;
}

export function detailPage(part) {
  const { relatedBrand, partGroup, partName, barcode } = part;

  return catalogPage({
    carType:    filterCargo(relatedBrand.brand),
    brand:      getTranslit(relatedBrand.brand),
    model:      getTranslit(relatedBrand.model),
    generation: getTranslit(relatedBrand.generation),
    partgroup:  getTranslit(partGroup),
    partname:   getTranslit(partName),
    barcode,
  }, { addSearch: false });
}

export function searchPage(params) {
  return `/search?${stringify(filterParams(params, searchPageParams))}`;
}

/**
 * Генерирует ссылку на страницу каталога или детального просмотра при редиректе.
 * @param {object} redirect
 * @param {0|1} redirect.cargo
 * @param {string} redirect.brand
 * @param {string} redirect.model
 * @param {string} redirect.generation
 * @param {string} redirect.partGroup
 * @param {string} redirect.partName
 * @param {string} [redirect.barcode]
 * @param {object} query
 * @return {string}
 */
export function universalPage(redirect, query) {
  const params = {
    ...query,
    carType:    filterCargo(redirect.cargo),
    brand:      redirect.brand,
    model:      redirect.model,
    generation: redirect.generation,
    partgroup:  redirect.partGroup,
    partname:   redirect.partName,
    barcode:    redirect.barcode,
  };
  return catalogPage(params);
}

export function newsList(page = 1) {
  return `/news/?page=${page}`;
}

export function news(item) {
  return `/news/${item.slug}`;
}
