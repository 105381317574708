export default ( url, params ) => window.fetch(url, params).then(( result ) => {
  const { status } = result;
  const contentType = result.headers.get('Content-Type');

  const res = (contentType && contentType.toLowerCase().includes('application/json'))
    ? result.json()
    : result.text();

  return res.then(response => ({ status, response }));
});
