import loadable from '@loadable/component';
import * as actions from '../../../../shared/redux/api/redux-connect-actions';

const component = loadable(() => import('./DetailPageContainer'));
component.preloadData = process.env.CLIENT ? [
  actions.getModels,
  actions.getGenerations,
  actions.getPartnamesExisting,
  actions.getBreadcrumbs,
  actions.getPart,
  actions.getRelatedParts,
  actions.getAttributes,
  // actions.getUniversalPage,
] : [
  actions.getUniversalPage,
];

export default component;

//DetailPageContainer
