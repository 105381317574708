import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
// import loader from './loader';
import setCookie from './setCookie';
import messageBroadcast from './messageBroadcast';

export default function (cookies, config, requester) {
  return applyMiddleware(
    thunk.withExtraArgument({ config, requester }),
    // loader,
    messageBroadcast,
    setCookie(cookies),
  );
}
