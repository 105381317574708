import { load, loadFail, loadSuccess } from '../redux/api/redux-load-actions';

const isCancelled = ( cancelled ) => {
  if (typeof cancelled !== 'function') return false;
  return cancelled();
};


/**
 *
 * @param {Object} options
 * @param {Object} options.store
 * @param {Requester} options.requester
 * @param {Object} options.params
 * @param {Object} action
 * @param {String} action.key
 * @param {Function} action.promise
 * @param {Function} [cancelled]
 * @returns {Promise<any>|*}
 */
export default function loadData( options, action, cancelled ) {
  const { key, promise } = action;
  const { store: { dispatch } } = options;
  const fn = promise(options);
  if (fn && typeof fn.then === 'function') {
    dispatch(load(action.key));
    // add action dispatchers
    fn.then(
      data => !isCancelled(cancelled) && dispatch(loadSuccess(key, data, options)),
      err => !isCancelled(cancelled) && dispatch(loadFail(key, err, options)),
    );
    return fn.catch(error => {
      if (error.status !== 404) throw(error);
      return null;
    });

  } else if (fn) {
    dispatch(loadSuccess(key, fn));
    return Promise.resolve(fn);
  }
  return Promise.reject();
}

export function setData( options, key, data ) {
  const { store: { dispatch } } = options;
  dispatch(loadSuccess(key, data, options));
  return data;
}
