import * as c from '../constants/constants';

const createAction = (type, payload) => ({ type, payload });

export const beginGlobalLoad = () => createAction(c.BEGIN_GLOBAL_LOAD);

export const endGlobalLoad = () => createAction(c.END_GLOBAL_LOAD);

export const load = key => createAction(c.LOAD, { key });

export const loadSuccess = (key, data, options) => createAction(c.LOAD_SUCCESS, { key, data, options });

export const loadFail = (key, error, options) => createAction(c.LOAD_FAIL, { key, error, options });
