import { createStore, compose } from 'redux';
import { reducer, initialState as initialStateDefault } from '../reducers/bigReducer';
import composeMiddlewares from '../middlewares';
import CookiesClient from '../../utils/cookies';

export default function configureStore(initialState = {}, config, requester, req, res) {
  const cookies = new CookiesClient(config, req, res);
  cookies.clearTrashCookies();
  const middlewares = composeMiddlewares(cookies, config, requester);

  const enhancers = !process.env.PRODUCTION && process.env.CLIENT && window.devToolsExtension
    ? compose(middlewares, window.__REDUX_DEVTOOLS_EXTENSION__())
    : middlewares;

  const store = createStore(reducer, { ...initialStateDefault, ...initialState }, enhancers);
  store.cookies = cookies;

  /* eslint-disable */
  if (!process.env.PRODUCTION && module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers').default;
      store.replaceReducer(nextReducer);
    });
  }
  /* eslint-enable */

  return store;
}
