import loadable from '@loadable/component';
import { getCart, getStorages } from '../../../redux/api/redux-connect-actions';


const component = loadable(() => import('./OrderPageContainer'));
component.preloadData = [
  getCart,
  getStorages,
];

export default component;
