// import config from '../../../config.json';

export default (cookies) => {
  return () => next => (action) => {
    if (action.cookieName !== undefined) {
      cookies.remove(action.cookieName);
      cookies.set(action.cookieName, action.cookieValue);
    }
    next(action);
  };
};
