/**
 * Преобразует строку c суммой в число
 * @param {String} value
 * @return {number|undefined}
 */
export function moneyToNumber(value) {
  if (!value) return undefined;
  return +value.replace(',', '.');
}

/**
 * Преобразует число в строку с суммой
 * @param {Number} value
 * @return {string}
 */
export function numberToMoney(value) {
  return (`${value}`).replace('.', ',');
}
