import Cookies from 'js-cookie';

class CookiesContainer {
  constructor(config) {
    this.config = config;
  }

  postfix() {
    return `-${this.config.ID}`;
  }

  getName(name) {
    return name + this.postfix();
  }

  getDefaultConfig() {
    const expires = new Date();
    expires.setYear(expires.getFullYear() + 1);

    return {
      expires,
      domain: this.config.DOMAIN,
    };
  };
}

class CookiesClientContainer extends CookiesContainer {
  constructor(config) {
    super(config);
    this.cookies = Cookies;
  }

  set(name, value, params) {
    return this.cookies.set(this.getName(name), value, { ...this.getDefaultConfig(), ...params });
  }

  get(name) {
    return this.cookies.get(this.getName(name));
  }

  clearTrashCookies() {
    const allCookies = this.cookies.get();
    for (const name in allCookies) {
      if (name.includes(this.postfix())) {
        this.cookies.remove(name);
      }
    }
  }

  remove(name, params) {
    return this.cookies.remove(this.getName(name), { ...this.getDefaultConfig(), ...params });
  }
}

class CookiesServerContainer extends CookiesContainer {
  constructor(config, req, res) {
    super(config);
    this.cookies = req.cookies;
    this.res = res;
  }

  set(name, value, params) {
    this.cookies[this.getName(name)] = value;
    return this.res.cookie(this.getName(name), value, { ...this.getDefaultConfig(), ...params });
  }

  get(name) {
    return this.cookies[this.getName(name)];
  }

  clearTrashCookies() {} //eslint-disable-line

  remove(name, params) {
    delete this.cookies[this.getName(name)];
    return this.res.clearCookie(this.getName(name), { ...this.getDefaultConfig(), ...params });
  }
}

export default process.env.CLIENT
  ? CookiesClientContainer
  : CookiesServerContainer;
