import { togglePrices } from '../redux/api/actions';

export default (store, config) => {
  if (config.PRICES_HIDDEN !== true) return;

  window.addEventListener('keydown', (event) => {
    const { target } = event;
    if (target.nodeName === 'INPUT' || target.nodeName === 'TEXTAREA') return;
    if (event.key === '!') {
      const state = store.getState();
      store.dispatch(togglePrices(!state.pricesVisible));
    }
  });
};
