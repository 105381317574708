import loadable from '@loadable/component';
import * as actions from '../../../../shared/redux/api/redux-connect-actions';

const component = loadable(() => import('./CatalogPageContainer'));
component.preloadData = process.env.CLIENT ? [
  actions.getModels,
  actions.getGenerations,
  actions.getPartnamesExisting,
  actions.getBreadcrumbs,
  actions.getSearch,
  actions.getAttributes,
  // actions.getCatalogPriceRange,
  // actions.getUniversalPage,
] : [
  actions.getUniversalPage,
];

export default component;
