import MessageBroadcast from './messageBroadcast';
// import { setCart, setUser, setCatalogView, setLastType, setCity } from '../redux/api/actions';
// import { cart as getCart } from '../redux/api/requests';
// import parseJSON from '../utils/parseJSON';
// import loadData from '../utils/loadData';
import { message } from '../redux/api/actions';

export default function init(store) {
  MessageBroadcast.listen(undefined, (value, key) => {
    store.dispatch(message(key, value));
  });

  // MessageBroadcast.listen('cart', (cart) => {
  //   // const parts = parseJSON(cart, []);
  //   // const state = store.getState();
  //   loadData({ store }, getCart);
  //   // getCart(parts, state.token)
  //   //   .then(res => store.dispatch(setCart(res)));
  // });
  //
  // MessageBroadcast.listen('user', (user) => {
  //   const objUser = parseJSON(user, {});
  //   const { token, profile } = objUser;
  //   if (token) {
  //     store.dispatch(setUser(token, profile));
  //   }
  // });
  //
  // MessageBroadcast.listen('catalogTemplate', (type) => {
  //   store.dispatch(setCatalogView(type));
  // });
  //
  // MessageBroadcast.listen('lastType', (carType) => {
  //   store.dispatch(setLastType(carType));
  // });
  //
  // MessageBroadcast.listen('city', (city) => {
  //   const objCity = parseJSON(city, null);
  //   store.dispatch(setCity(objCity));
  // });
}
