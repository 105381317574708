/**
 * @typedef {Object} Phone
 * @prop {String} code - код
 * @prop {String} number - номер
 */

/**
 * Разбивает строку с телефоном на код и номер
 * Телефон в формате x yyy zzz zz zz
 * @param {String} phone
 * @return {Phone || null}
 */
export default (phone) => {
  if (!phone) return null;
  const countryCode = phone.indexOf('8800') === 0 ? '8' : '+7';
  return {
    code:   `${countryCode} (${phone.substr(1, 3)})`,
    number: `${phone.substr(4, 3)} ${phone.substr(7, 2)} ${phone.substr(9, 2)}`,
  };
};
