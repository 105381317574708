export default function (obj, path, defaultValue) {
  if (!obj) return undefined;
  let objWalker = obj;
  if (!path) return obj;
  const arrPath = Array.isArray(path) ? path : path.split('.');
  for (let i = 0; i < arrPath.length; i++) {
    if (typeof objWalker === 'undefined') return defaultValue;
    if (!objWalker) return objWalker;
    objWalker = objWalker[arrPath[i]];
  }
  return objWalker;
}
