import isObject from './isObject';

export default function isEmpty(value) {
  if (!isObject(value)) return false;
  for (const key in value) {
    if ({}.hasOwnProperty.call(value, key) && value[key] !== undefined) {
      return false;
    }
  }
  return true;
}
