import * as c from '../constants/constants';
import * as API from '../../utils/API';

export function show404() {
  return {
    type: c.NOT_FOUND,
  };
}

export function clear404() {
  return {
    type: c.CLEAR_NOT_FOUND,
  };
}

export const setUser = (user) => (dispatch, getState, { requester }) => {
  requester.setToken(user.token);
  dispatch({
    type:         c.SET_USER,
    messageName:  'user',
    messageValue: user,
    cookieName:   'token',
    cookieValue:  user.token,
  });
};

export function setCart(cart, item) {
  return {
    type:         c.SET_CART,
    item,
    messageName:  'cart',
    messageValue: cart,
    // cookieName:   'cart',
    // cookieValue:  cartStringify(cart),
  };
}

export function showCartPopup(item) {
  return {
    type: c.SHOW_CART_POPUP,
    item,
  };
}

export const addItem = (item) => (dispatch, getState, { requester }) => {
  return requester.send(API.addToCart(item)).then(res => dispatch(setCart(res)));
};

export const removeItem = (item) => (dispatch, getState, { requester }) => {
  return requester.send(API.removeFromCart(item)).then(res => dispatch(setCart(res)));
};

export const clearCart = () => (dispatch, getState, { requester }) => {
  return requester.send(API.clearCart()).then(res => dispatch(setCart(res)));
};

export const recalcCart = () => (dispatch, getState, { requester }) => {
  requester.send(API.recalcCart()).then(res => dispatch(setCart(res)));
};

export function hidePopup() {
  return {
    type: c.HIDE_CART_POPUP,
  };
}

export function setCatalogView(value) {
  return {
    type:         c.CHANGE_CATALOG_VIEW,
    messageName:  'catalogTemplate',
    messageValue: value,
    cookieName:   'catalogTemplate',
    cookieValue:  value,
  };
}

export function showGallery(item, index) {
  return {
    type: c.SHOW_GALLERY,
    item,
    index,
  };
}

export function hideGallery() {
  return {
    type: c.HIDE_GALLERY,
  };
}

export function setCarType(carType) {
  return {
    type:         c.SET_LAST_TYPE,
    messageName:  'carType',
    messageValue: carType,
    cookieName:   'carType',
    cookieValue:  carType,
  };
}

export function loadPartnames(partnames) {
  return {
    type: c.LOAD_PARTNAMES,
    partnames,
  };
}

export function setLastParams(params) {
  return {
    type: c.SET_LAST_PARAMS,
    params,
  };
}

export function setPhone(phone) {
  return {
    type: c.SET_PHONE,
    phone,
  };
}

export function setCities(cities) {
  return {
    type: c.SET_CITIES,
    cities,
  };
}

export const setCity = (city) => (dispatch, getState, { requester }) => {
  return requester.send(API.setCity(city)).then(() => dispatch({
    type:         c.CHANGE_CITY,
    messageName:  'city',
    messageValue: city,
    cookieName:   'city',
    cookieValue:  city,
  }));
};

export function message(key, value) {
  return {
    type: c.MESSAGE,
    key,
    value,
  };
}

export const clearPartQuery = () => ({
  type: c.CLEAR_PART_QUERY,
});

export const closeWarningMessage = () => ({
  type:         c.CLOSE_WARNING_MESSAGE,
  messageName:  'warningHidden',
  messageValue: true,
  cookieName:   'warningHidden',
  cookieValue:  true,
});

export const togglePrices = pricesVisible => ({
  type:         c.TOGGLE_PRICES,
  messageName:  'pricesVisible',
  messageValue: pricesVisible,
  cookieName:   'pricesVisible',
  cookieValue:  pricesVisible,
});
