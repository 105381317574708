import loadable from '@loadable/component';
import { getNewsList } from '../../../redux/api/redux-connect-actions';
import { NEWS_PER_PAGE } from 'src/constants';

const component = loadable(() => import('./NewsPageContainer'));
component.preloadData = [
  getNewsList(NEWS_PER_PAGE),
];

export default component;
