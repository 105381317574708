import stringify from 'qs/lib/stringify';
import * as API  from '../API';

const fetch = process.env.SERVER
  ? require('./serverFetch').default
  : require('./clientFetch').default;

export default class Requester {
  constructor( config ) {
    this.baseURL = {
      API:  process.env.CLIENT
              ? /api/
              : `https://shop.${process.env.API_DOMAIN}/v1/`,
      BASE: process.env.CLIENT
              ? '/'
              : `https://${config.DOMAIN}/`,
    };
    this.token = null;
    this.cookieClient = null;
    this.refreshInProgress = null;
  }

  setToken( token ) {
    this.token = token;
  }

  setCookieClient( cookieClient ) {
    this.cookieClient = cookieClient;
  }

  refreshToken() {
    if (!this.refreshInProgress) {
      const action = this.send(API.login());
      action.then(( user ) => {
        // if (_isObjectLike(store) && store.dispatch) store.dispatch(setUser(user));
        this.token = user.token;
        this.refreshInProgress = null;
      });
      this.refreshInProgress = action;
    }
    return this.refreshInProgress;
  };


  send( [method, type, url, body] ) {
    const startTime = new Date();
    const upperMethod = method.toString().toUpperCase();
    let fullUrl = this.baseURL[type] + url;

    const params = {
      method: upperMethod,
    };

    if (upperMethod === 'GET' || upperMethod === 'DELETE') {
      const strBody = stringify(body);
      if (strBody.length) {
        fullUrl += `?${stringify(body)}`;
      }
    } else if (typeof body === 'object' && body !== null) {
      params.body = JSON.stringify(body);
    } else {
      params.body = body;
    }

    params.headers = { 'Content-Type': 'application/json' };
    if (this.token) params.headers['SHOP-AUTH-TOKEN'] = this.token;
    if (type === 'BASE')
      if (process.env.SERVER) {
        let cookie = '';
        for (let cookieName in this.cookieClient.cookies) {
          cookie += cookieName + '=' + this.cookieClient.cookies[cookieName] + '; ';
        }
        params.headers.cookie = cookie;

      } else {
        params.credentials = 'include';
      }

    // if (process.env.SERVER) console.log(fullUrl + ' : ' + JSON.stringify(body) + ': start'); // eslint-disable-line
    return fetch(fullUrl, params, this.cookieClient).then(( { status, response } ) => {
      if (status === 401) { // Unauthorized
        return this.refreshToken().then(() => this.send([method, type, url, body]));
      }

      if (process.env.SERVER) console.log(fullUrl + ' : ' + JSON.stringify(body) + ': ' + (new Date() - startTime)); // eslint-disable-line

      if (status < 200 || status >= 300) {
        console.log(response);
        return Promise.reject({ url, params, status, error: response });
      } // eslint-disable-line
      return response;
    });
  }
}
