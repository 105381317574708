/**
 * Отбрасыает из объекта все пустые и "all" значения
 * @param {object} params
 * @return {object}
 */
export default function filterParams(params) {
  const buffer = {};
  for (const i in params) {
    if ({}.hasOwnProperty.call(params, i)) {
      const param = params[i];
      if (param !== null && param !== undefined && param !== 'all') {
        buffer[i] = param;
      }
    }
  }
  return buffer;
}
