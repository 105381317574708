const catalogLoadable = require('./../desktop/containers/CatalogPage/index').default;
export default {
  App:                    require('./../desktop/containers/App/index').default,
  BrandList:              require('./../desktop/containers/BrandList/index').default,
  CartPage:               require('./../desktop/containers/CartPage/index').default,
  CatalogPage:            catalogLoadable,
  CatalogModelsPage:      catalogLoadable,
  CatalogGenerationsPage: catalogLoadable,
  CheckOrderPage:         require('./../desktop/containers/CheckOrderPage/index').default,
  ConfirmPage:            require('./../desktop/containers/ConfirmPage/index').default,
  DetailPage:             require('./../desktop/containers/DetailPage/index').default,
  ForgotPage:             require('./../desktop/containers/ForgotPage/index').default,
  FrontPage:              require('./../desktop/containers/FrontPage/index').default,
  LoginPage:              require('./../desktop/containers/LoginPage/index').default,
  NewsPage:               require('./../desktop/containers/NewsPage/index').default,
  NewsSinglePage:         require('./../desktop/containers/NewsSinglePage/index').default,
  NotFound:               require('./../desktop/components/NotFound/index').default,
  OrderPage:              require('./../desktop/containers/OrderPage/index').default,
  OrdersPage:             require('./../desktop/containers/OrdersPage/index').default,
  OrdersSinglePage:       null,
  PartnersPage:           require('./../desktop/containers/PartnersPage/index').default,
  PaymentPage:            require('./../desktop/containers/PaymentPage/index').default,
  ProfilePage:            require('./../desktop/containers/ProfilePage/index').default,
  RegisterPage:           require('./../desktop/containers/RegisterPage/index').default,
  ResetPage:              require('./../desktop/containers/ResetPage/index').default,
  SearchPage:             require('./../desktop/containers/YandexSearchPage/index').default,
  StaticPage:             require('./../desktop/containers/StaticPage/index').default,
  // TestPage:       ().default => import('./../desktop/containers/TestPage/index').default,
};
