import { CATALOG_AVAILABLE_SORT } from 'src/constants';

const sortToStrKey = sorting => `${sorting.sort}${sorting.order}`;

const srtToSortIndex = {};
const sortToStrIndex = {};

for (const i in CATALOG_AVAILABLE_SORT) {
  if ({}.hasOwnProperty.call(CATALOG_AVAILABLE_SORT, i)) {
    const sorting = CATALOG_AVAILABLE_SORT[i];

    srtToSortIndex[sorting.name] = {
      sort:  sorting.sort,
      order: sorting.order,
    };

    sortToStrIndex[sortToStrKey(sorting)] = sorting.name;
  }
}

/**
 *
 * @param {string} str
 */
export const strToSort = str => srtToSortIndex[str];

/**
 *
 * @param {object} sorting
 * @param {string} sorting.sort
 * @param {string} sorting.order
 */
export const sortToStr = (sorting) => {
  if (!sorting) return undefined;
  return sortToStrIndex[sortToStrKey(sorting)];
};
