import browser from './browser';

const addEvent = process.env.CLIENT ? require('./addEvent').default : null;

const listeners = {};
let listen = () => {};
let broadcast = () => {};

if (process.env.CLIENT) {
  const __TEST__ = '__TEST__';
  const ls = window.localStorage;

  const detect = () => {
    try {
      ls.setItem(__TEST__, __TEST__);
      ls.removeItem(__TEST__);
      return true;
    } catch (e) {
      return false;
    }
  };

  const change = (key, value) => {
    if (Array.isArray(listeners.all)) {
      listeners.all.forEach(cb => cb(value, key));
    }
    if (Array.isArray(listeners[key])) {
      listeners[key].forEach(cb => cb(value, key));
    }
  };

  const onStorage = (e) => {
    const { key, newValue } = e;
    if (key === __TEST__) return;
    change(key, newValue);
  };

  const supported = detect();
  if (supported) {
    if (browser.msie && (+browser.version < 9)) {
      addEvent(document, 'storage', onStorage);
    } else {
      addEvent(window, 'storage', onStorage);
    }
  }

  listen = (key = 'all', cb) => {
    if (!listeners[key]) {
      listeners[key] = [];
    }
    listeners[key].push(cb);
  };

  broadcast = (key, value) => {
    const strValue = typeof value !== 'string' ? JSON.stringify(value) : value;
    if (supported) {
      ls.setItem(key, strValue);
    }
    change(key, strValue);
  };
}


export default { listen, broadcast };
