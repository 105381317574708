// import { LOAD, LOAD_SUCCESS, LOAD_FAIL, BEGIN_GLOBAL_LOAD, END_GLOBAL_LOAD } from 'redux-connect/lib/store';
import * as c        from '../constants/constants';
import parseJSON     from '../../utils/parseJSON';
import parsePhone    from '../../utils/parsePhone';
import { sortToStr } from '../../utils/sortingConverter';

export const initialState = {
  // generations:  null,
  // models:       {},
  // partgroups:   {},
  // partnames:    {},
  brands:       {},
  breadcrumbs:  {},
  cart:         {},
  cartLoading:  false,
  cartPopup:    { visible: false, item: null },
  cities:       null,
  galleryPopup: { visible: false, item: null, index: 0 },
  lastParams:   {},
  notFound:     false,
  orders:       {},
  page:         {},
  part:         null,
  profile:      {},
  related:      {},
  search:       { loaded: false },
  storages:     null,
  token:        null,
};

function groupPartgroups( partgroups ) {
  if (!Array.isArray(partgroups)) return null;
  return partgroups.map(( pg ) => {
    return {
      id:            pg.id,
      title:         pg.title,
      titleTranslit: pg.titleTranslit,
      titleSearch:   pg.title.toLowerCase(),
    };
  });
}

function groupPartnames( partgroups, fullRequest ) {
  if (!Array.isArray(partgroups)) return null;
  let hasError = false;
  const buf = {};
  for (const i in partgroups) {
    if ({}.hasOwnProperty.call(partgroups, i)) {
      const pg = partgroups[i];
      const partnames = [];
      for (const j in pg.partNames) {
        if ({}.hasOwnProperty.call(pg.partNames, j)) {
          const pn = pg.partNames[j];
          if (pn) {
            partnames.push({
              id:            pn.id,
              title:         pn.title,
              titleTranslit: pn.titleTranslit,
              titleSearch:   pn.title.toLowerCase(),
            });
          } else {
            hasError = true;
          }
        }
      }
      buf[pg.titleTranslit] = partnames;
    }
  }

  return buf;
}

function getFilter( data ) {
  if (!data.filter) return undefined;
  return {
    ...data.filters,
    sort: sortToStr(data.filters),
    page: data.filters.offset / data.filters.limit + 1,
  };
}

// todo: костыль. Надо вырезать universal_page
function cutPartNames( parts ) {
  if (parts && parts.data) {
    for (let i = parts.data.length - 1; i >= 0; i--) {
      delete parts.data[i].partGroup.partNames; //eslint-disable-line
    }
  }
  return parts;
}

const loadReducer = {
  search: state => ({
    ...state,
    search: { ...state.search, loaded: false },
  }),
};

const loadSuccessReducer = {
  related:       ( state, { payload: { data } } ) => ({
    ...state,
    related: data.data,
  }),
  brands:        ( state, { payload: { data, options } } ) => ({
    ...state,
    brands: {
      ...state.brands,
      [options.params.carType || 'cars']: data,
    },
  }),
  orders:        ( state, { payload: { data } } ) => ({
    ...state,
    orders: data.orders,
  }),
  exist:         ( state, { payload: { data } } ) => ({
    ...state,
    partgroups: data ? groupPartgroups(data) : state.partgroups,
    partnames:  data ? groupPartnames(data) : state.partnames,
  }),
  storages:      ( state, { payload: { data } } ) => ({
    ...state,
    storages: data,
  }),
  catalogCities: ( state, { payload: { data } } ) => {
    return {
      ...state,
      catalogCities: [
        { id: null, value: 'Все' },
        ...data.map(city => ({ id: `${city.id}`, value: city.title })),
      ],
    };
  },
  universalPage: ( state, { payload: { data } } ) => ({
    ...state,
    redirect:              data.redirect,
    breadcrumbs:           data.breadcrumbs,
    partgroups:            groupPartgroups(data.partGroups),
    partnames:             groupPartnames(data.partGroups, data),
    search:                { ...cutPartNames(data.parts), loaded: true },
    part:                  data.part,
    catalogPriceRange:     data.minMaxPrices,
    attributes:            data.attributes,
    related:               data.relativeParts,
    filters:               getFilter(data),
    [data.carsType]:       data.cars,
    universalPageNotFound: false,
  }),
  search:        ( state, { payload: { data } } ) => ({
    ...state,
    search:                { ...data, loaded: true },
    universalPageNotFound: false,
  }),
};

const loadFailedReducer = {
  universalPage: state => ({
    ...state,
    universalPageNotFound: true,
  }),
};

const messageReducer = {
  cart:          ( state, action ) => ({ ...state, cart: parseJSON(action.value, []) }),
  user:          ( state, action ) => {
    const user = parseJSON(action.value, {});
    return {
      ...state,
      token:   user.token,
      profile: user.user,
    };
  },
  // city:          (state, action) => {
  //   const city = parseJSON(action.value, null);
  //   return {
  //     ...state,
  //     city,
  //     phone: parsePhone(selectPhone(state, city)),
  //   };
  // },
  warningHidden: state => ({
    ...state,
    warningHidden: true,
  }),

  pricesVisible: ( state, { value } ) => ({
    ...state,
    pricesVisible: value === 'true',
  }),
};

const mainReducer = {
  [c.BEGIN_GLOBAL_LOAD]: state => ({
    ...state,
    reduxAsyncConnect: { loaded: false },
  }),
  [c.END_GLOBAL_LOAD]:   state => ({
    ...state,
    reduxAsyncConnect: { loaded: true },
  }),
  [c.LOAD]:              ( state, action ) => {
    const { payload: { key } } = action;
    return loadReducer[key]
      ? loadReducer[key](state, action)
      : state;
  },
  [c.LOAD_SUCCESS]:      ( state, action ) => {
    const { payload: { data, key } } = action;
    if (loadSuccessReducer[key]) return loadSuccessReducer[key](state, action);
    if (data !== undefined && state[key] !== data) return { ...state, [key]: data };
    return state;
  },
  [c.LOAD_FAIL]:         ( state, action ) => {
    const { payload: { key } } = action;
    if (loadFailedReducer[key]) return loadFailedReducer[key](state, action);
    if (action.payload.error.status === 404 || action.payload.error === 'NOT_FOUND') {
      return { ...state, notFound: true };
    }
    return state;
  },
  [c.MESSAGE]:           ( state, action ) => {
    return messageReducer[action.key]
      ? messageReducer[action.key](state, action)
      : { ...state, [action.key]: action.value };
  },
  [c.SHOW_CART_POPUP]:   ( state, { item } ) => {
    if (!item) return state;
    return {
      ...state,
      cartPopup: {
        visible: true,
        item,
      },
    };
  },
  [c.HIDE_CART_POPUP]:   state => ({
    ...state,
    cartPopup: {
      visible: false,
      item:    null,
    },
  }),
  [c.SHOW_GALLERY]:      ( state, action ) => ({
    ...state,
    galleryPopup: {
      visible: true,
      item:    action.item,
      index:   action.index,
    },
  }),
  [c.HIDE_GALLERY]:      state => ({
    ...state,
    galleryPopup: {
      visible: false,
      item:    null,
      index:   0,
    },
  }),
  [c.CLEAR_NOT_FOUND]:   state => ({
    ...state,
    notFound:     false,
    galleryPopup: {
      visible: false,
      item:    null,
      index:   0,
    },
    cartPopup:    {
      visible: false,
      item:    null,
    },
  }),
  [c.NOT_FOUND]:         state => ({
    ...state,
    notFound: true,
  }),
  [c.SET_LAST_PARAMS]:   ( state, { params } ) => ({
    ...state,
    lastParams: params,
  }),
  [c.SET_PHONE]:         ( state, { phone } ) => ({
    ...state,
    phone: parsePhone(phone),
  }),
  [c.SET_CITIES]:        ( state, { cities } ) => ({
    ...state,
    cities,
  }),
  [c.CLEAR_PART_QUERY]:  state => ({
    ...state,
    partQuery: null,
  }),
};

export const reducer = ( state = initialState, action ) => {
  const { type } = action;
  return mainReducer[type]
    ? mainReducer[type](state, action)
    : state;
};
