export const DESKTOP_PARTS_PER_PAGE = 21;
export const MOBILE_PARTS_PER_PAGE = 10;
export const NEWS_PER_PAGE = 6;

export const PHONE_MASK = '+7 (999) 999-99-99';
export const PHONE_REG_EXP = /^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/;
export const EMAIL_REG_EXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]*[a-z0-9]@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

export const SEARCH_LIMIT = 10000;

export const CATALOG_AVAILABLE_SORT = [
  { name: 'priceAsc', sort: 'markupForPartners', order: 'ASC' },
  { name: 'priceDesc', sort: 'markupForPartners', order: 'DESC' },
  { name: 'usedAsc', sort: 'used', order: 'ASC' },
  { name: 'usedDesc', sort: 'used', order: 'DESC' },
];

export const ORDER_FIELDS = {
  courierFields: [
    {
      name:       'city',
      label:      'Город',
      maxlength:  30,
      validators: [(value) => { return value && value.trim() ? null : ['Введите город']; }],
    },
    // {
    //   name:       'city',
    //   label:      'Город',
    //   maxlength:  30,
    //   validators: [(value) => { return value && value.trim() ? null : ['Введите город']; }],
    // },
    // {
    //   name:       'street',
    //   label:      'Улица',
    //   maxlength:  50,
    //   validators: [(value) => { return value && value.trim() ? null : ['Введите улицу']; }],
    // },
    // {
    //   name:       'building',
    //   label:      'Дом, корпус',
    //   maxlength:  10,
    //   validators: [(value) => { return value && value.trim() ? null : ['Введите номер дома']; }],
    // },
    // {
    //   name:      'apartment',
    //   label:     'Квартира',
    //   maxlength: 10,
    // },
    // {
    //   name:      'zipcode',
    //   label:     'Индекс',
    //   maxlength: 10,
    // },
  ],
  confirmFields: {
    self:    address => [
      { label: 'Способ получения', value: 'Самовывоз' },
      { label: 'Место получения', value: address },
    ],
    courier: address => [
      { label: 'Способ получения', value: 'Доставка' },
      { label: 'Адрес доставки', value: address },
    ],
  },
};
