/* eslint "no-param-reassign": 0 */
import stringify         from 'qs/lib/stringify';
import * as API          from '../utils/API';
import { universalPage } from '../utils/links';
// import { show404, setCarType } from '../redux/api/actions';

const oldCatalog = process.env.SERVER ? require('./../oldCatalog.json') : null;

export default ( store, getComponent ) => {
  const checkAuth = ( context, params, query, url ) => {
    const state = store.getState();
    if (!state.profile) context.redirect = `/login?redirect=${url}`;
  };

  const checkNotAuth = ( context, params, query ) => {
    const state = store.getState();
    if (state.profile) context.redirect = query.redirect || '/';
  };

  const onEnterCatalog = ( context, params, query ) => {
    const state = store.getState();
    if (state.universalPageNotFound) {
      context.notFound = true;
      return;
    }
    if (state.redirect) {
      context.redirect = universalPage(state.redirect, query);
      return;
    }

    // todo: some kind of KOSTIL
    if (process.env.CLIENT) return;
    if (query.page > 476) {
      const { carType, brand, model, generation, partgroup, partname } = params;
      const search = stringify({ query, page: 1 });
      context.redirect = `/${carType}/${brand}/${model}/${generation}/${partgroup}/${partname}?${search}`;
    }
  };

  function generateLink( part ) {
    const brand = part.relatedBrand;
    const brandType = brand.brand.cargo ? 'trucks' : 'cars';
    return `/${brand.brand ? brandType : 'all'}/${
      brand.brand ? brand.brand.titleTranslit : 'all'}/${
      brand.model ? brand.model.titleTranslit : 'all'}/${
      brand.generation ? brand.generation.titleTranslit : 'all'}/${
      part.partGroup ? part.partGroup.titleTranslit : 'all'}/${
      part.partName ? part.partName.titleTranslit : 'all'}/${
      part.barcode}/`;
  }

  return [
    {
      name:      'app',
      component: getComponent('App'),
      routes:    [
        {
          name:      'front',
          path:      '/',
          exact:     true,
          component: getComponent('FrontPage'),
        },
        // {
        //   path:      '/test',
        //   component: getComponent('TestPage'),
        // },
        {
          path:      '/order',
          component: getComponent('OrderPage'),
        },
        {
          path:      '/payment/success',
          component: getComponent('PaymentPage'),
        },
        {
          path:      '/payment/fail',
          component: getComponent('PaymentPage'),
        },
        {
          path:      '/login',
          component: getComponent('LoginPage'),
          onEnter:   checkNotAuth,
        },
        {
          path:      '/register',
          component: getComponent('RegisterPage'),
          onEnter:   checkNotAuth,
        },
        {
          path:      '/forgot',
          component: getComponent('ForgotPage'),
          onEnter:   checkNotAuth,
        },
        {
          path:      '/reset/:code',
          component: getComponent('ResetPage'),
          onEnter:   checkNotAuth,
        },
        {
          path:      '/activate/:userId/:code',
          component: getComponent('ConfirmPage'),
          onEnter:   checkNotAuth,
        },
        {
          path:      '/cabinet',
          exact:     true,
          component: getComponent('ProfilePage'),
          onEnter:   checkAuth,
        },
        {
          path:      '/cabinet/orders',
          component: getComponent('OrdersPage'),
          onEnter:   checkAuth,
        },
        {
          path:      '/cabinet/orders/:orderId',
          component: getComponent('OrdersSinglePage'),
          onEnter:   checkAuth,
        },
        {
          path:      '/check_order',
          component: getComponent('CheckOrderPage'),
        },
        {
          path:      '/page/:pageSlug',
          component: getComponent('StaticPage'),
          onEnter:   ( context ) => {
            const state = store.getState();
            if (!state.page || !state.page._id) context.notFound = true;
          },
        },
        {
          path:      '/cart',
          component: getComponent('CartPage'),
        },
        {
          path:      '/search',
          component: getComponent('SearchPage'),
        },
        {
          path:      '/partners',
          component: getComponent('PartnersPage'),
        },
        {
          path:      '/news/:slug',
          component: getComponent('NewsSinglePage'),
        },
        {
          path:      '/news',
          component: getComponent('NewsPage'),
        },
        {
          // redirect for old catalog
          // /passenger/changan/cs35_s_2013_g/kuzov_naruzhnye_elementy/dver_zadnyaya_174502/
          path:    '/passenger/:oldBrand/:oldModelGeneration/:oldPartgroup/:oldPartname',
          onEnter: ( context, params ) => {
            if (process.env.CLIENT) return null;
            const partname = params.oldPartname.split('_');
            const bitrixId = partname[partname.length - 1];
            const pid = oldCatalog[bitrixId];

            if (!pid) {
              context.redirect = '/';
              return null;
            }
            return API.part({ barcode: pid }, store).then(
              ( part ) => {
                context.redirect = generateLink(part);
              },
              () => {
                context.redirect = '/';
              });
          },
        },
        {
          // redirect for old catalog
          path:    '/part/:barcode',
          exact:   true,
          onEnter: ( context, params ) => {
            if (process.env.CLIENT) return null;
            return API.part({ barcode: params.barcode }, store).then(
              ( part ) => {
                context.redirect = generateLink(part);
              },
              () => {
                context.redirect = '/';
              });
          },
        },
        {
          path:      '/:carType',
          exact:     true,
          component: getComponent('BrandList'),
          onEnter:   ( context, params ) => {
            const { carType } = params;
            if (carType === 'passenger') {
              context.redirect = '/cars';
              return;
            }
            if (carType !== 'cars' && carType !== 'trucks') {
              context.notFound = true;
              return;
            }
            context.redirect = '/';
          },
        },
        {
          path:      '/:carType/:brand/all/all/:partgroup/:partname/',
          exact:     true,
          component: getComponent('CatalogModelsPage'),
          onEnter:   onEnterCatalog,
        },
        {
          path:      '/:carType/:brand/:model/all/:partgroup/:partname/',
          exact:     true,
          component: getComponent('CatalogGenerationsPage'),
          onEnter:   onEnterCatalog,
        },
        {
          path:      '/:carType/:brand/:model/:generation/:partgroup/:partname/',
          exact:     true,
          component: getComponent('CatalogPage'),
          onEnter:   onEnterCatalog,
        },
        {
          path:      '/:carType/:brand/:model/:generation/:partgroup/:partname/:barcode',
          exact:     true,
          component: getComponent('DetailPage'),
          onEnter:   onEnterCatalog,
        },
        {
          path:      '*',
          component: getComponent('NotFound'),
          onEnter:   ( context ) => {
            context.notFound = true;
          },
        },
      ],
    },
  ];
};
