export const NOT_FOUND = 'NOT_FOUND';
export const CLEAR_NOT_FOUND = 'CLEAR_NOT_FOUND';

export const SET_CART = 'SET_CART';
export const SHOW_CART_POPUP = 'SHOW_CART_POPUP';

export const LOGIN = 'LOGIN';
export const PROFILE = 'PROFILE';
export const LOGOUT = 'LOGOUT';
export const SET_USER = 'SET_USER';

export const HIDE_CART_POPUP = 'HIDE_CART_POPUP';

export const CHANGE_CATALOG_VIEW = 'CHANGE_CATALOG_VIEW';
export const SET_CATALOG_VIEW = 'SET_CATALOG_VIEW';

export const SHOW_GALLERY = 'SHOW_GALLERY';
export const HIDE_GALLERY = 'HIDE_GALLERY';

export const SET_LAST_TYPE = 'SET_LAST_TYPE';
export const SET_LAST_PARAMS = 'SET_LAST_PARAMS';

export const LOAD_PARTNAMES = 'LOAD_PARTNAMES';
export const LOAD_CART = 'LOAD_CART';

export const SET_PHONE = 'SET_PHONE';

export const SET_CITIES = 'SET_CITIES';
export const CHANGE_CITY = 'CHANGE_CITY';
export const SET_CITY = 'SET_CITY';

export const MESSAGE = 'MESSAGE';

export const CLEAR_PART_QUERY = 'CLEAR_PART_QUERY';
export const CLOSE_WARNING_MESSAGE = 'CLOSE_WARNING_MESSAGE';
export const TOGGLE_PRICES = 'TOGGLE_PRICES';

export const LOAD = 'LOAD';
export const LOAD_SUCCESS = 'LOAD_SUCCESS';
export const LOAD_FAIL = 'LOAD_FAIL';
export const BEGIN_GLOBAL_LOAD = 'BEGIN_GLOBAL_LOAD';
export const END_GLOBAL_LOAD = 'END_GLOBAL_LOAD';
