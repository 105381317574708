/* eslint no-param-reassign: [0] */
import React from 'react';
import ReactDOM from 'react-dom';
import BrowserRouter from 'react-router-dom/BrowserRouter';
import { Provider } from 'react-redux';
// import Loadable from 'react-loadable';
import { loadableReady } from '@loadable/component';

import routes from '../shared/router/routes';
import configureStore from '../shared/redux/store/configureStore';
import messages from '../shared/utils/messages';
import { reachGoal } from '../shared/utils/metrika';
import Router from '../shared/components/Router';
import AppConfig from '../shared/components/AppConfig';
import priceToggler from '../shared/utils/priceToggler';
import Requester from '../shared/utils/fetch/Requester';

// import { to } from '../shared/utils/scroll';
// import { clear404 } from '../shared/redux/api/actions';

// import calltouch from '../shared/utils/callintouch';
// function onLoadEnd(prevRouterProps, routerProps) {
//   if (routerProps.location.action === 'POP') return null;
//   if (routerProps.components) {
//     const component = routerProps.components[routerProps.components.length - 1];
//     if (component.WrappedComponent && typeof component.WrappedComponent.onLoadEnd === 'function') {
//       return component.WrappedComponent.onLoadEnd(prevRouterProps, routerProps);
//     }
//   }
//   return to(0);
// }

export default (components) => {
  const config = window.__CONFIG__;
  const requester = new Requester(config);
  const store = configureStore(window.__INITIAL_STATE__, config, requester);
  const state = store.getState();
  const clientRoutes = routes(store, component => (components[component] ? components[component] : null));
  requester.setToken(state.token);
  // let inited = false;
  messages(store);
  priceToggler(store, config);

  reachGoal(`ver${state.ab}`);

  // Loadable.preloadReady().then(() => {
  loadableReady(() => {
    const app = (
      <AppConfig.Provider value={{ config, requester }}>
        <Provider store={store}>
          <BrowserRouter>
            <Router routes={clientRoutes} hit />
          </BrowserRouter>
        </Provider>
      </AppConfig.Provider>
    );
    ReactDOM.hydrate(app, document.getElementById('root'));
  });


  // const matchConfig = {
  //   location: window.location,
  //   routes:   routes(store, component => (components[component] ? components[component] : null)),
  //   history,
  // };
  // match(matchConfig, (err, redirectInfo, renderedProps) => {
  //   if (err) return;
  //   if (!renderedProps) return;
  //
  //   renderedProps.history = history;
  //
  //   // При смене города перезагружать asyncProps
  //   const state = store.getState();
  //   let lastCity = state.city && state.city.id;
  //   const mapStateToProps = (newState) => {
  //     const city = newState.city && newState.city.id;
  //     const map = { city };
  //     lastCity = city;
  //     return map;
  //   };
  //   const WrappedReduxAsyncConnect = connect(mapStateToProps)(ReduxAsyncConnect);
  //
  //   renderedProps.render = props => (
  //     <WrappedReduxAsyncConnect
  //       {...props}
  //       lastCity={lastCity}
  //       onLoadEnd={onLoadEnd}
  //     />
  //   );
  //   renderedProps.onUpdate = () => {
  //     if (inited) store.dispatch(clear404());
  //   };
  //
  //   const render = () => {
  //     const toRender = (
  //       <Provider store={store}>
  //         <Router {...renderedProps} />
  //       </Provider>
  //     );
  //
  //     hydrate(toRender, document.getElementById('root'));
  //   };
  //
  //   render();
  //   inited = true;
  //   // calltouch(store);
  //
  //   reachGoal(`ver${state.ab}`);
  // });
};

