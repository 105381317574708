import * as API                                                         from '../../utils/API';
import { MOBILE_PARTS_PER_PAGE, DESKTOP_PARTS_PER_PAGE, NEWS_PER_PAGE } from 'src/constants';
import isEmpty                                                          from '../../utils/isEmpty';
import filterParams                                                     from '../../utils/filterParams';
// import { numberToMoney } from '../../utils/money';
// import { ATTRIBUTE_PREFIX } from '../../utils/links';
import urlToParamsConverter                                             from '../../utils/urlToParamsConverter';
import isKeysEquals                                                     from '../../utils/isKeysEquals';

const emptySearch = { items: [], pager: {}, options: {} };
const emptyAttributes = [];
const compare = {
  b:  'brand',
  m:  'model',
  g:  'generation',
  pg: ['partGroup', 'partgroup'],
  pn: ['partName', 'partname'],
};

// const cityChanged = options => options.city !== options.lastCity;

export const getBrands = {
  key:     'brands',
  promise: ( { store, requester, params } ) => {
    const state = store.getState();
    const type = params.carType || 'cars';
    if (state.brands && state.brands[type]) {
      return Promise.resolve(state.brands[type]);
    }
    return requester.send(API.brands(type));
  },
};

export const getCatalogCities = {
  key:     'catalogCities',
  promise: ( { store, requester } ) => {
    const state = store.getState();
    if (state.catalogCities) {
      return Promise.resolve(state.catalogCities);
    }
    return requester.send(API.catalogCities());
  },
};

export const getCatalogPriceRange = {
  key:     'catalogPriceRange',
  promise: ( { params, store, requester } ) => {
    return requester.send(API.catalogPriceRange(filterParams(params)));
  },
};

export const getModels = {
  key:     'models',
  promise: ( { params, requester, query, store, city, lastCity } ) => {
    const state = store.getState();
    const urlParams = urlToParamsConverter(params, query, state);
    const lastParams = filterParams(state.lastParams);

    // список сбрасываются чтобы при переходе вверх по каталогу не мерцал список моделей
    if (urlParams[compare.m]) return Promise.resolve(null);

    if (
      city === lastCity &&
      isKeysEquals([compare.b, compare.m, compare.pg, compare.pn], params, lastParams)
    ) {
      return Promise.resolve(state.models);
    }

    urlParams.isAvailable = 1;
    return requester.send(API.models(urlParams));
  },
};

export const getGenerations = {
  key:     'generations',
  promise: ( { store, requester, params, query, city, lastCity } ) => {
    const state = store.getState();
    const urlParams = urlToParamsConverter(params, query, state);
    const lastParams = filterParams(state.lastParams);
    const { model } = urlParams;

    // список сбрасываются чтобы при переходе вверх по каталогу не мерцал список поколений
    if (urlParams[compare.g]) return Promise.resolve(null);

    if (!model || model === 'all') return Promise.resolve(null);

    if (
      city === lastCity &&
      isKeysEquals([compare.b, compare.m, compare.g, compare.pg, compare.pn], params, lastParams)
    ) {
      return Promise.resolve(state.generations);
    }

    urlParams.isAvailable = 1;
    return requester.send(API.generations(urlParams));
  },
};

export const getBreadcrumbs = {
  key:     'breadcrumbs',
  promise: ( { store, requester, params, query } ) => {
    const state = store.getState();
    const urlParams = urlToParamsConverter(params, query, state);
    const lastParams = filterParams(state.lastParams);

    if (isKeysEquals([compare.b, compare.m, compare.g, compare.pg, compare.pn], params, lastParams)) {
      return Promise.resolve(state.breadcrumbs);
    }

    return requester.send(API.breadcrumbs(urlParams));
  },
};

export const getPartnamesExisting = {
  key:     'exist',
  promise: ( { store, requester, params, query, city, lastCity } ) => {
    const state = store.getState();
    const urlParams = urlToParamsConverter(params, query, state);
    const lastParams = filterParams(state.lastParams);
    if (
      city === lastCity &&
      isKeysEquals([compare.b, compare.m, compare.g], params, lastParams)
    ) {
      return Promise.resolve(null);
    }

    return requester.send(API.partnamesExist(urlParams));
  },
};

export const getAttributes = {
  key:     'attributes',
  promise: ( { params, store, requester } ) => {
    const urlParams = filterParams(params);

    if (!urlParams.partname) return Promise.resolve(emptyAttributes);

    return requester.send(API.attributes(urlParams.partname));
  },
};

export const getSearch = {
  key:     'search',
  promise: ( { store, requester, params, query } ) => {
    const state = store.getState();
    const urlParams = urlToParamsConverter(params, query, state);

    if (!urlParams) return Promise.resolve(emptySearch);
    return requester.send(API.search(urlParams));
  },
};

export const getPart = {
  key:     'part',
  promise: ( { store, requester, params, query } ) => {
    const state = store.getState();
    const urlParams = urlToParamsConverter(params, query, state);
    return requester.send(API.part(urlParams));
  },
};

export const getRelatedParts = {
  key:     'related',
  promise: ( { params, store, requester } ) => {
    const urlParams = filterParams(params);
    const query = {
      brand:      urlParams.brand,
      model:      urlParams.model,
      generation: urlParams.generation,
      partGroup:  urlParams.partgroup,
      partName:   urlParams.partname,
      exclude:    urlParams.barcode,
      offset:     0,
      limit:      21,
    };
    return requester.send(API.relaterParts(query));
  },
};

export const getStaticPage = {
  key:     'page',
  promise: ( { params, requester, store } ) => {
    return requester.send(API.page(params.pageSlug)).catch(() => null);
  },
};

export const getCart = {
  key:     'cart',
  promise: ( { requester } ) => {
    return requester.send(API.getCart());
  },
};

export const getStorages = {
  key:     'storages',
  promise: ( { store, requester } ) => {
    const state = store.getState();
    if (state.storages) {
      return Promise.resolve(state.storages);
    }
    return requester.send(API.availableStorages());
  },
};

export const getOrders = {
  key:     'orders',
  promise: ( { store, requester } ) => {
    return requester.send(API.orders('all'));
  },
};

export const getOrder = {
  key:     'order',
  promise: ( { params, store, requester } ) => {
    const { orderId } = params;
    return requester.send(API.order(orderId));
  },
};

export const getDetectum = {
  key:     'detectum',
  promise: ( { query, store, requester } ) => {
    const state = store.getState();
    const perPage = state.env === 'mobile' ? MOBILE_PARTS_PER_PAGE : DESKTOP_PARTS_PER_PAGE;
    // const prevSearch = state.detectum;
    const { type, page } = query;
    const params = {
      ...query,
      cargo:        type === 'trucks' ? 1 : 0,
      offset:       perPage * ((+page || 1) - 1),
      page:         undefined,
      limit:        perPage,
      partgroup_id: undefined,
    };
    // if (params.partgroup_id) {
    // params.category_id = params.partgroup_id;
    // params.partgroup_id = undefined;
    // }
    if (params.partname_id) {
      params.category_id = params.partname_id;
      params.partname_id = undefined;
    }

    return requester.send(API.detectum(params)).then(( res ) => {
      return isEmpty(res) ? null : res;
    });
  },
};

export const getUniversalPage = {
  key:     'universalPage',
  promise: ( { params, store, requester, query } ) => {
    const state = store.getState();
    const urlParams = urlToParamsConverter(params, query, state);
    return requester.send(API.universalSearch(urlParams));
  },
};

export const getNewsList = limit => ({
  key:     'newsList',
  promise: ( { query, store, requester } ) => {
    const offset = NEWS_PER_PAGE * ((+query.page || 1) - 1);
    return requester.send(API.newsList({ limit, offset }));
  },
});

export const getNews = {
  key:     'news',
  promise: ( { params, store, requester } ) => {
    const { slug } = params;
    return requester.send(API.newsSingle(slug));
  },
};
