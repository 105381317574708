import filterParams from './filterParams';
import { numberToMoney } from './money';
import { MOBILE_PARTS_PER_PAGE, DESKTOP_PARTS_PER_PAGE } from 'src/constants';
import { strToSort } from './sortingConverter';

const getSellingPrice = (query) => {
  if (!query.minPrice && !query.maxPrice) return null;
  return {
    from: query.minPrice && numberToMoney(query.minPrice),
    to:   query.maxPrice && numberToMoney(query.maxPrice),
  };
};

/**
 * Конвертирует
 * @param {object} params
 * @param {object} query
 * @param {object} state
 * @return {object}
 */
export default function urlToParamsConverter(params, query, state) {
  const filteredParams = filterParams(params);

  if (!filteredParams.brand) return null;

  const sorting = query.sort ? strToSort(query.sort) : undefined;
  const limit = state.env === 'mobile' ? MOBILE_PARTS_PER_PAGE : DESKTOP_PARTS_PER_PAGE;
  const offset = limit * ((+query.page || 1) - 1);
  return {
    cargo:        filteredParams.carType === 'trucks' ? 1 : 0,
    brand:        filteredParams.brand,
    model:        filteredParams.model,
    generation:   filteredParams.generation,
    partGroup:    filteredParams.partgroup,
    partName:     filteredParams.partname,
    barcode:      filteredParams.barcode,
    limit,
    offset,
    sort:         sorting && sorting.sort,
    order:        sorting && sorting.order,
    used:         query.used,
    city:         query.city,
    sellingPrice: getSellingPrice(query),
    attr:         query.attr,
    // attr:         query.attr,
  };
}
