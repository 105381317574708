import memoize from 'fast-memoize';
import get from 'src/shared/utils/get';

const getMetrikaId = () => {
  const { Ya } = window;
  return get(Ya, ['_metrika', 'wv2Counter']);
};

export const reachGoal = memoize((goal) => {
  return () => {
    console.log('reachGoal', goal);
    const { ym, ga } = window;
    const metrika_id = getMetrikaId();
    if (metrika_id && ym) ym(metrika_id, 'reachGoal', goal);
    if (ga) ga('send', 'event', 'button', goal);
  };
});

export const hit = (url, options) => {
  const { ym, ga } = window;
  const metrika_id = getMetrikaId();
  if (metrika_id && ym) ym(metrika_id, 'hit', url, options);
  if (ga) {
    ga('set', 'page', url);
    ga('send', 'pageview');
  }
};
