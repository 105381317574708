import * as React from 'react';
import AppConfig from './AppConfig';

export default AppConfig;

export const connectAppConfig = (Component) => {
  class AppConfigConnect extends React.PureComponent {
    renderContent = (context) => <Component {...context} {...this.props} />;

    render() {
      return <AppConfig.Consumer>{this.renderContent}</AppConfig.Consumer>;
    }
  }

  return AppConfigConnect;
};
