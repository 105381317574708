/**
 * Сравнивает значения указанных ключей в объектах
 * @param {string[]|Array.<string, string>[]} keys
 * @param {object} obj1
 * @param {object} obj2
 * @return {boolean}
 */
export default function isKeysEquals(keys, obj1, obj2) {
  return keys.every((key) => {
    if (Array.isArray(key)) return obj1[key[0]] === obj2[key[1]];
    return obj1[key] === obj2[key];
  });
}
