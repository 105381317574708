const cargo = type => (type === 'trucks' ? 1 : 0);
const GET = 'GET';
const POST = 'POST';
const PUT = 'PUT';
const API = 'API';
const BASE = 'BASE';

export const brands = ( type ) => [GET, API, 'brands', { cargo: cargo(type) }];
export const models = ( params ) => [GET, API, `brands/${params.brand}/models`, params];
export const generations = ( params ) => [GET, API, `models/${params.model}/generations`, params];
export const breadcrumbs = ( params ) => [GET, API, `breadcrumbs`, params];
export const attributes = ( partname ) => [GET, API, `partnames/${partname}/attributes`];
export const partnamesExist = ( params ) => [GET, API, `partnames/existing`, params];
export const search = ( params ) => [GET, API, `parts/search`, params];
export const part = ( params ) => [POST, API, `part/${params.barcode}`, params];
export const relaterParts = ( params ) => [GET, API, `parts/isearch`, params];
export const page = ( slug ) => [GET, BASE, `static/${slug}`];
export const storages = ( storageIds ) => [GET, API, `order/storages`, { storageIds }];
export const availableStorages = () => [GET, BASE, `action/storages`];
export const catalogCities = () => [GET, API, `cities/inuse`];
export const catalogPriceRange = ( params ) => [GET, API, `getmaxminprices`, params];
export const detectum = ( params ) => [GET, API, `detectum`, params];
// export const searchSuggest = (term) => [GET, API, `suggest`, { term }];
export const universalSearch = ( params ) => [POST, API, `universal_page`, params];

const cartAction = ( url, params ) => [POST, BASE, url, params, { cookies: true }];
export const addToCart = ( item ) => cartAction(`action/cart/add`, { item });
export const removeFromCart = ( item ) => cartAction(`action/cart/remove`, { item });
export const getCart = () => cartAction(`action/cart`);
export const clearCart = () => cartAction(`action/cart/clear`);
export const recalcCart = () => cartAction(`action/cart/recalc`);

export const cart = ( parts ) => [POST, API, `cart`, { parts }];

export const login = ( user ) => [POST, BASE, `action/login`, user];
export const apiLogin = ( user, company_id ) => [POST, API, `login`, { company_id, ...user }];

export const setCity = ( city ) => [PUT, API, `city/current`, city];
export const register = ( user ) => [POST, API, `register`, user];
export const resend = ( email ) => [POST, API, `resend`, email];
export const reset = ( user ) => [POST, API, `reset_password`, user];
export const changePassword = ( password, token ) => [POST, API, `reset_confirm`, { password, token }];
export const activate = ( user, code ) => [POST, API, `profile/activate/${user}/${code}`];
export const profile = () => [GET, API, `profile`];
export const profilePrices = () => [GET, API, `profile/prices`];
export const updateProfile = ( userProfile ) => [PUT, API, `profile`, userProfile];

export const orders = ( status ) => [GET, API, `orders`, { status }];
export const order = ( id ) => [GET, API, `order/${id}`];
export const orderStatus = ( orderId, captchaCode ) => [GET, API, `order/${orderId}/status`, { captcha: captchaCode }];

export const captcha = ( type ) => [GET, API, `checkspam/${type}`];
export const sendCode = ( data ) => [POST, API, `smscode`, data];
export const createOrder = ( orderInfo ) => [POST, API, `order`, orderInfo];
export const getPaymentUrl = ( id ) => [GET, API, `order/${id}/paymentUrl`];
export const validatePayment = ( params ) => [GET, API, `payment/success`, params];
export const cities = ( title ) => [GET, API, `cities/search`, { title }];

export const partnerForm = ( form ) => [POST, API, `feedback`, { ...form, type: 'integration' }];

export const newsList = ( pagination ) => [GET, API, `news`, pagination];
export const newsSingle = ( slug ) => [GET, API, `news/${slug}`];

// export function partgroups(token) {
//   return fetch('GET', `${apiUrl}partgroups`, null, { token });
// }

// export function partnames(partgroup, token) {
//   return fetch('GET', `${apiUrl}partgroups/${partgroup}/partnames`, { all: 1 }, { token });
// }

// export function partnamesAll(token) {
//   return fetch('GET', `${apiUrl}partnames`, null, { token });
// }

// export function brand(type, brandTitle, token) {
//   return fetch('GET', `${apiUrl}brands/${brandTitle}`, { cargo: cargo(type) }, { token, cache: true });
// }
