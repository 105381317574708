import PropTypes from 'prop-types';
// import * as InternalPropTypes from 'react-router/lib/InternalPropTypes';
// import * as RouterPropTypes from 'react-router/lib/PropTypes';

const { objectOf, arrayOf, array, oneOf, oneOfType, shape, string, bool, number, func, object } = PropTypes;

export PropTypes from 'prop-types';

export const query = objectOf(oneOfType([
  string,
  array,
  shape({}),
]));

export const location = shape({
  hash:     PropTypes.string,
  key:      PropTypes.string,
  pathname: PropTypes.string,
  search:   PropTypes.string,
});

export const history = shape({
  action:     string,
  block:      func,
  createHref: func,
  go:         func,
  goBack:     func,
  goForward:  func,
  length:     number,
  listen:     func,
  location,
  push:       func,
  replace:    func,
});

export const params = objectOf(string);

export const route = shape({
  component: object,
  exact:     bool,
  name:      string,
  onEnter:   func,
  path:      string,
});
route.routes = arrayOf(route);

export const routes = PropTypes.arrayOf(route);

// export const { routes } = InternalPropTypes;
// export const router = RouterPropTypes.routerShape;

export const children = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]);

export const part = shape({
  barcode:      string,
  brands:       arrayOf(shape()),
  defects:      string,
  id:           string,
  // id:           number,
  injury:       arrayOf(shape()),
  kod:          string,
  notes:        string,
  original:     bool,
  partGroup:    shape(),
  partName:     shape(),
  photos:       arrayOf(arrayOf(string)),
  relatedBrand: objectOf(shape()),
  sellingPrice: string,
  shopComment:  string,
  used:         bool,
});

export const parts = arrayOf(part);

export const cart = shape({
  cart:  parts,
  total: shape({
    summ:     string,
    quantity: number,
  }),
});

export const profile = shape({
  id:         number,
  email:      string,
  name:       string,
  lastName:   string,
  middlename: string,
  phone:      string,
});

export const brand = shape({
  cargo:         bool,
  id:            number,
  isPopular:     bool,
  title:         string,
  titleTranslit: string,
});

export const brands = arrayOf(brand);

export const page = shape({
  content: string,
  id:      number,
  slug:    string,
  title:   string,
});

export const pager = shape({
  totalRows: number,
  offset:    number,
  limit:     number,
});

export const image = shape({
  fileName: string,
  path:     string,
});

export const search = shape({
  data: arrayOf(part),
  pager,
});

export const breadcrumbs = objectOf(string);

export const modelOrGeneration = shape({
  hasParts:      bool,
  id:            number,
  image:         shape(),
  isPopular:     bool,
  showAlways:    bool,
  title:         string,
  titleTranslit: string,
});

export const models = arrayOf(modelOrGeneration);

export const generations = arrayOf(modelOrGeneration);

export const partname = shape({
  id:            number,
  title:         string,
  titleTranslit: string,
});

export const partnames = arrayOf(partname);

export const partgroup = shape({
  id:            number,
  // partNames:     partnames,
  title:         string,
  titleTranslit: string,
});

export const partgroups = arrayOf(partgroup);

export const partTreeItem = shape({
  active:   bool,
  expanded: bool,
  id:       oneOfType([string, number]),
  items:    arrayOf(shape()), // recursive to partTreeItem
  link:     oneOfType([string, bool]),
  title:    string,
  type:     string,
  visible:  bool,
});

export const partTree = arrayOf(partTreeItem);

export const storage = shape({
  address: string,
  code:    string,
  id:      number,
  phone:   string,
  title:   string,
});

export const storages = arrayOf(storage);

export const deliveryType = shape({
  id:    number,
  label: string,
  value: string,
});

export const order = shape({
  comment:         string,
  created:         string,
  deliveryAddress: string,
  deliveryType,
  id:              number,
  name:            string,
  // needDelivery:    string,
  needDelivery:    bool,
  paymentType:     string,
  total:           string,
});

export const orders = arrayOf(order);

export const phone = shape({
  code:   string,
  number: string,
});

export const attribute = shape({
  id:              oneOfType([number, string]),
  title:           string,
  type:            oneOf(['choise', 'boolean', 'integer', 'text', 'select']),
  availableValues: arrayOf(shape({
    id:    oneOfType([number, string]),
    value: string,
  })),
});

export const attributes = arrayOf(attribute);

export const city = shape({
  id:    oneOfType([number, string]),
  title: string,
});

export const cities = arrayOf(city);

export const detectum = shape({
  cars:       arrayOf(modelOrGeneration),
  carType:    oneOf(['brands', 'models', 'generations', 'none']),
  partGroups: partgroups,
  parts,
  request:    shape({
    limit:  number,
    offset: number,
    q:      string,
  }),
  total:      number,
});

export const priceRange = shape({
  minPrice: string,
  maxPrice: string,
});

export const news = shape({
  content: string,
  created: string,
  id:      number,
  image:   arrayOf(string),
  title:   string,
});

export const newsList = shape({
  data: arrayOf(news),
  pager,
});
